import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '@/utils/helpers/toast';
import { selectExport } from '@/store/export/exportSelectors';
import { downloadFile, submitExport } from '@/store/export/exportThunks';
import {
  cdnUrl,
  changeStatus,
  resetExportState,
} from '@/store/export/exportSlice';
import { AppDispatch, store } from '@/store';

const MAX_RETRIES = 3;

const ActionButtons = () => {
  const {
    columns,
    endpoint,
    permission,
    selectedColumns,
    filters,
    documentID,
  } = useSelector(selectExport);
  const dispatch: AppDispatch = useDispatch();

  const autoDownload = (path: string) => {
    const link = document.createElement('a');
    link.href = `${cdnUrl}/${path}`;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const retryDownloadFile = async (
    fileDocumentID: string,
    maxRetries: number = MAX_RETRIES
  ): Promise<boolean> => {
    const attempts = Array.from({ length: maxRetries }, (_, i) => i + 1);

    return attempts.reduce(async (prevAttempt, attempt) => {
      const success = await prevAttempt;

      if (success) {
        return true; // Eğer önceki deneme başarılıysa işlemi sonlandır
      }

      try {
        dispatch(changeStatus('delayed'));

        await new Promise<void>((resolve, reject) => {
          const interval = setInterval(() => {
            const updatedState = store.getState();
            if (updatedState.export.isAborted) {
              clearInterval(interval);
              reject(new Error('İşlem iptal edildi')); // İşlem iptal edilirse promise hata fırlatır
            }
          }, 500); // 500ms aralıklarla kontrol edin

          setTimeout(() => {
            clearInterval(interval);
            resolve();
          }, 3000); // 3 saniye sonra devam et
        });

        dispatch(changeStatus('loading'));

        const response = await dispatch(
          downloadFile({ documentID: fileDocumentID })
        ).unwrap();

        if (response.path) {
          autoDownload(response.path);
          return true; // Dosya başarıyla indirildi
        }
      } catch (error) {
        if (attempt === maxRetries) {
          return false; // Maksimum deneme hakkı doldu
        }
      }

      return false; // Bir sonraki denemeye geç
    }, Promise.resolve(false));
  };

  const handleExport = async () => {
    const body = {
      columns: columns.reduce(
        (acc: { [key: string]: boolean }, col: { name: string }) => {
          acc[col.name] = !!selectedColumns[col.name];
          return acc;
        },
        {}
      ),
      filters,
    };

    try {
      const exportResponse = await dispatch(
        submitExport({ endpoint, body, permission })
      ).unwrap();

      const downloadSuccess = await retryDownloadFile(
        exportResponse?.documentID
      );

      if (!downloadSuccess) {
        await dispatch(
          downloadFile({
            documentID: exportResponse?.documentID as string,
            isEmailSend: true,
          })
        ).then(() => {
          dispatch(changeStatus('success'));

          addToast({
            severity: 'warn',
            summary: 'Bilgi',
            detail: 'Dosya e-posta ile gönderilecektir.',
          });
        });
      } else {
        addToast({
          severity: 'success',
          summary: 'Başarılı',
          detail: 'Dosya başarıyla indirildi.',
        });
      }
    } catch (error) {
      addToast({
        severity: 'error',
        summary: 'Hata',
        detail: 'Export işlemi sırasında bir hata oluştu.',
      });
    }
  };

  const handleReset = () => {
    dispatch(resetExportState());
  };

  return (
    <div className="flex justify-end mt-8">
      {!documentID && (
        <Button
          label="Dışarı Aktar"
          icon="icon-[mdi--file-export-outline] size-6"
          onClick={handleExport}
          disabled={
            Object.values(selectedColumns).filter((value) => value === true)
              .length === 0
          }
        />
      )}
      {documentID && (
        <Button
          label="Sıfırla"
          icon="icon-[mdi--refresh] size-6"
          className="ml-2"
          onClick={handleReset}
        />
      )}
    </div>
  );
};

export default ActionButtons;
