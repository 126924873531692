import {
  PickList,
  PickListChangeEvent,
  PickListProps,
} from 'primereact/picklist';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import type { Control, FieldValues, Path } from 'react-hook-form';

interface OptionType {
  id: string;
  name: string;
}

interface IProps<T extends FieldValues> extends Omit<PickListProps, 'dataKey'> {
  control: Control<T>;
  label?: string;
  name: Path<T>;
  wrapperClassName?: string;
  emptyMessage?: string;
  loading?: boolean;
  dataKey?: keyof OptionType;
  options?: OptionType[];
  priorityField?: 'source' | 'target';
  onSourceChange?: (event: PickListChangeEvent['source']) => void;
  selectedCountLabel?: string;
  availableCountLabel?: string;
}

const ControlledPickList = <T extends FieldValues>({
  control,
  label,
  name,
  wrapperClassName,
  emptyMessage,
  loading,
  options,
  onSourceChange,
  dataKey = 'id',
  priorityField = 'target',
  selectedCountLabel = 'Seçilen kayıt sayısı', // Default value
  availableCountLabel = 'Mevcut kayıt sayısı', // Default value
  ...pickListProps
}: IProps<T>) => {
  const { field, fieldState, formState } = useController({
    control,
    name,
  });

  const [source, setSource] = useState<OptionType[]>([]);
  const [target, setTarget] = useState<OptionType[]>([]);

  useEffect(() => {
    if (field.value && options) {
      const initialTarget = options.filter((option) =>
        field.value.includes(option[dataKey as keyof OptionType])
      );
      const newSource = options.filter(
        (option) => !initialTarget.some((t) => t.id === option.id)
      );

      setSource(newSource);
      setTarget((prev) => {
        const updatedTarget = [...prev, ...initialTarget];
        return Array.from(
          new Map(updatedTarget.map((item) => [item.id, item])).values()
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, options, dataKey]);

  const handleChange = (event: PickListChangeEvent) => {
    if (onSourceChange) {
      onSourceChange(event.source);
    }
    setSource(event.source);
    setTarget(event.target);

    field.onChange(
      event?.[priorityField].map(
        (item: OptionType) => item[dataKey as keyof OptionType]
      )
    );
  };

  return (
    <div className={classNames(wrapperClassName, 'custom-picklist')}>
      {label && (
        <label
          htmlFor={label}
          className={classNames({ 'p-error': formState.errors.value })}
        >
          {label}
        </label>
      )}
      <input type="hidden" {...field} />
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <i className="pi pi-spinner pi-spin text-red-600 dark:text-gray-300 text-7xl" />
          </div>
        )}
        <PickList
          dataKey={dataKey as string}
          sourceHeader={<div>{availableCountLabel}</div>}
          targetHeader={selectedCountLabel}
          filter
          filterBy="name"
          onChange={handleChange}
          target={target}
          source={source}
          targetStyle={{ height: '24rem' }}
          sourceStyle={{ height: '24rem' }}
          {...pickListProps}
          className={classNames(
            { 'p-invalid': fieldState.error },
            'w-full mt-2 overflow-y-auto',
            pickListProps.className
          )}
        />
      </div>
      <div className="mt-4 mr-2 flex justify-between">
        <p className="p-error">
          {availableCountLabel}: {source.length}
        </p>
        <p className="p-error">
          {selectedCountLabel}: {target.length}
        </p>
      </div>
      {!pickListProps.source?.length && (
        <small className="p-error">{emptyMessage}</small>
      )}
      {fieldState.error ? (
        <small className="p-error">{fieldState.error?.message as string}</small>
      ) : (
        <small className="p-error">&nbsp;</small>
      )}
    </div>
  );
};

export default ControlledPickList;
