export const getQueryParams = (search: string) => {
  const params = new URLSearchParams(search);
  const filters: { [key: string]: string | boolean } = {};

  const parseValue = (value: string): string | boolean => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  };

  params.forEach((value, key) => {
    const newKey = key === 'q' ? 'query' : key; // TODO: refactor
    filters[newKey] = parseValue(value);
  });

  delete filters.page;
  delete filters.perPage;

  return filters;
};

export const filterDictionary: Record<string, string> = {
  query: 'İsim',
  isActive: 'Aktif mi?',
  isClassroom: 'Derslik mi?',
  departmentName: 'Departman Adı',
  campusName: 'Kampüs Adı',
};

export const filterValueDictionary: Record<string, string> = {
  true: 'Evet',
  false: 'Hayır',
};
