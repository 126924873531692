import { Button, ButtonProps } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React from 'react';

interface ButtonVariant {
  severity: ButtonProps['severity'];
  className?: string;
  icon?: string;
  label: string;
  type?: ButtonProps['type'];
  text?: boolean;
  size?: ButtonProps['size'];
}

export interface CustomButtonProps extends ButtonProps {
  variant:
    | 'create'
    | 'save'
    | 'reset'
    | 'search'
    | 'edit'
    | 'status'
    | 'delete'
    | 'detail'
    | 'preview'
    | 'join'
    | 'report'
    | 'download'
    | 'upload'
    | 'help'
    | 'export';
}

export const variants: Record<CustomButtonProps['variant'], ButtonVariant> = {
  create: {
    severity: 'info',
    className: 'h-11 mt-1.5 self-center',
    icon: 'pi pi-plus',
    label: 'Oluştur',
    type: 'submit',
  },
  save: {
    severity: 'info',
    className: 'h-11 mt-1.5 col-span-full w-full',
    label: 'Kaydet',
    type: 'submit',
    icon: 'pi pi-save',
  },
  reset: {
    severity: 'secondary',
    className: 'h-11 mt-1.5 self-center w-full',
    type: 'reset',
    label: 'Temizle',
  },
  search: {
    severity: 'danger',
    className: 'h-11 mt-1.5 self-center w-full',
    type: 'submit',
    label: 'Ara',
  },
  edit: {
    severity: 'info',
    text: true,
    type: 'submit',
    label: 'Düzenle',
    size: 'small',
    icon: 'pi pi-pencil',
  },
  status: {
    severity: 'success',
    text: true,
    type: 'button',
    label: '',
    size: 'small',
    icon: '',
  },
  delete: {
    severity: 'danger',
    text: true,
    type: 'button',
    label: 'Sil',
    size: 'small',
    icon: 'pi pi-minus',
  },
  detail: {
    severity: 'warning',
    text: true,
    label: 'Detay',
    icon: 'pi pi-info-circle',
    size: 'small',
    type: 'button',
  },
  preview: {
    size: 'small',
    severity: 'warning',
    label: ' Önizle',
    text: true,
    icon: 'pi pi-eye',
  },
  join: {
    size: 'small',
    severity: 'info',
    label: ' Katıl',
    text: false,
    icon: 'pi pi-at',
  },
  help: {
    size: 'small',
    className: 'h-11 mt-1.5 col-span-full w-full',
    severity: 'help',
    label: 'Rehber',
    icon: 'pi pi-question-circle text-base',
  },
  report: {
    size: 'small',
    severity: 'help',
    label: 'Rapor',
    text: true,
    icon: 'pi pi-chart-bar',
  },
  download: {
    size: 'small',
    severity: 'success',
    label: 'İndir',
    icon: 'pi pi-download',
    className: 'h-11 mt-1.5',
  },
  upload: {
    size: 'small',
    severity: 'success',
    label: 'İçe Aktar',
    icon: 'pi pi-upload',
    className: 'h-11 mt-1.5',
  },
  export: {
    size: 'small',
    severity: 'success',
    label: 'Dışa Aktar',
    icon: 'pi pi-download',
    className: 'h-11 mt-1.5',
  },
};

const CustomButton: React.FC<CustomButtonProps> = ({ variant, ...props }) => {
  const { className, ...variantProps } = variants[variant];

  return (
    <Button
      className={classNames(className, props.className)}
      {...variantProps}
      {...props}
    />
  );
};

export default CustomButton;
