import { hasAllCampusesAccess } from '@/utils/helpers/campus';

const userTheme = localStorage.getItem('theme');
const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

export const isDarkMode =
  userTheme === 'dark' || (!('theme' in localStorage) && systemTheme);

export const combineArrayWithAllOption = (
  array: any[] = [],
  hasAllOption = false,
  allOption: { id?: string; name?: string } = { id: 'all', name: 'Tümü' }
) => {
  const all = hasAllOption ? [allOption] : [];
  return all.concat(array);
};

export const combineEmptyAllOption = (
  array: any[] = [],
  hasAllOption = false
) => {
  return combineArrayWithAllOption(array, hasAllOption, {
    id: '',
    name: 'Tümü',
  });
};

export const removeEmptyFields = (data: any) => {
  const newData = { ...data };
  Object.keys(newData).forEach((key) => {
    if (newData[key] === '' || newData[key] == null) {
      delete newData[key];
    }
  });
  return newData;
};

export const convertToTrChar = (text: string) => {
  const charMap = new Map([
    ['ı', 'i'],
    ['ç', 'c'],
    ['ş', 's'],
    ['ö', 'o'],
    ['ü', 'u'],
    ['ğ', 'g'],
    ['Ç', 'C'],
    ['Ş', 'S'],
    ['Ö', 'O'],
    ['Ü', 'U'],
    ['Ğ', 'g'],
    ['İ', 'I'],
  ]);
  return text
    ?.split('')
    ?.map((char) => charMap.get(char) || char)
    ?.join('');
};

export const calculateSizePercentage = (loaded: number, total: number) =>
  Math.round((loaded * 100) / (total ?? 1));

export const isFullURL = (str: string) =>
  str.indexOf('http://') === 0 || str.indexOf('https://') === 0;

export const trimAndNormalizeSpaces = (value: string): string => {
  return value.replace(/\s+/g, ' ');
};

export const isMobile = /android.+mobile|ip(hone|[oa]d)/i.test(
  navigator.userAgent
);

export const isClearButtonAllowed = (
  campusID: string | undefined,
  permisson: string
) => {
  return (
    campusID !== undefined &&
    campusID !== 'all' &&
    hasAllCampusesAccess(permisson)
  );
};
