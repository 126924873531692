import { createSlice } from '@reduxjs/toolkit';
import { fetchExportFields, submitExport, downloadFile } from './exportThunks';
import { Operations } from '@/utils/enums/Operations';

interface ExportState {
  isOpenExportModal: boolean;
  endpoint: string;
  columns: { name: string; key: string }[];
  filters: { [key: string]: string };
  documentID: string | null;
  selectedColumns: { [key: string]: boolean };
  isDownloaded: boolean;
  filePath: string | null;
  status:
    | 'idle'
    | 'loading'
    | 'fetch-loading'
    | 'success'
    | 'error'
    | 'delayed';
  error: string | null;
  permission: Operations;
  isAborted?: boolean;
}

const initialState: ExportState = {
  isOpenExportModal: false,
  endpoint: '',
  columns: [],
  filters: {},
  documentID: null,
  selectedColumns: {},
  isDownloaded: false,
  filePath: null,
  status: 'idle',
  error: null,
  permission: Operations.ExportPlace,
  isAborted: false,
};

export const cdnUrl = import.meta.env.VITE_CDN_URL;

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    openExportModal(
      state,
      action: {
        payload: {
          endpoint: string;
          permission: Operations;
        };
      }
    ) {
      state.isOpenExportModal = true;
      state.endpoint = action.payload.endpoint;
      state.permission = action.payload.permission;
    },
    closeExportModal(state) {
      state.isOpenExportModal = false;
      state.endpoint = '';
      /* state.documentID = null; */
      state.error = null;
      state.status = 'idle';
      state.filePath = null;
      state.isDownloaded = false;
      state.permission = Operations.ExportPlace;
      state.isAborted = false;
    },
    resetDocumentID(state) {
      state.documentID = null;
    },
    updateColumn(state, action) {
      const columnName = action.payload;
      state.selectedColumns[columnName] = !state.selectedColumns[columnName];
    },
    updateFilter(state, action) {
      state.filters = action.payload;
    },
    resetExportState(state) {
      state.documentID = null;
      state.filePath = null;
      state.isDownloaded = false;
      state.selectedColumns = {};
      state.error = null;
      state.status = 'idle';
      state.isAborted = false;
    },
    selectAllColumns(state) {
      state.columns.forEach((col) => {
        state.selectedColumns[col.name] = true;
      });
    },
    deselectAllColumns(state) {
      state.columns.forEach((col) => {
        state.selectedColumns[col.name] = false;
      });
    },
    changeStatus(state, action) {
      state.status = action.payload;
    },
    abortExport(state) {
      state.isAborted = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExportFields.pending, (state) => {
        state.status = 'fetch-loading';
        state.error = null;
      })
      .addCase(fetchExportFields.fulfilled, (state, action) => {
        state.status = 'success';
        state.columns = action.payload.columns;
      })
      .addCase(fetchExportFields.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message || 'Bir hata oluştu.';
      })
      .addCase(submitExport.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(submitExport.fulfilled, (state, action) => {
        state.status = 'success';
        state.documentID = action.payload.documentID;
      })
      .addCase(submitExport.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message || 'Export işlemi başarısız oldu.';
      })
      .addCase(downloadFile.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.status = 'success';
        state.isDownloaded = true;

        if (!action.payload) {
          state.filePath = '';
          return;
        }

        state.filePath = action.payload.isEmailSend ? '' : action.payload.path;
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message || 'Dosya indirilemedi.';
      });
  },
});

export const {
  openExportModal,
  closeExportModal,
  updateColumn,
  updateFilter,
  resetExportState,
  resetDocumentID,
  selectAllColumns,
  deselectAllColumns,
  changeStatus,
  abortExport,
} = exportSlice.actions;

export default exportSlice.reducer;
