import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { selectExport } from '@/store/export/exportSelectors';
import { fetchExportFields } from '@/store/export/exportThunks';
import { AppDispatch } from '@/store';
import { closeExportModal, resetDocumentID } from '@/store/export/exportSlice';
import ExportModalContent from '@/components/ExportComponent/ExportModalContent';

const ExportModal = () => {
  const { isOpenExportModal, endpoint, permission, status } =
    useSelector(selectExport);
  const dispatch: AppDispatch = useDispatch();

  const getExportFields = () => {
    dispatch(fetchExportFields({ endpoint, permission }));
  };

  const onClose = () => {
    if (status !== 'loading' && status !== 'delayed') {
      dispatch(closeExportModal());
      setTimeout(() => {
        dispatch(resetDocumentID());
      }, 500);
    }
  };

  useEffect(() => {
    if (isOpenExportModal) {
      getExportFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenExportModal]);

  return (
    <Dialog
      header="Dışarı Aktarma Seçenekleri"
      visible={isOpenExportModal}
      className="w-full md:w-1/2 m-4"
      onHide={onClose}
    >
      <ExportModalContent />
    </Dialog>
  );
};

export default ExportModal;
