import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'primereact/message';
import FileLoader from '@/components/ExportComponent/FileLoader';
import ColumnsAndFilters from '@/components/ExportComponent/ColumnsAndFilters';
import StatusMessage from '@/components/ExportComponent/StatusMessage';
import ActionButtons from '@/components/ExportComponent/ActionButtons';
import { selectExport } from '@/store/export/exportSelectors';
import { abortExport } from '@/store/export/exportSlice';
import { AppDispatch } from '@/store';

const ExportModalContent = () => {
  const { documentID, isAborted, status } = useSelector(selectExport);

  const dispatch: AppDispatch = useDispatch();

  const onAbort = () => {
    dispatch(abortExport());
  };

  if (status === 'fetch-loading') {
    return (
      <div className="flex justify-center items-center">
        <ProgressSpinner />
      </div>
    );
  }

  if (status === 'loading' || status === 'delayed') {
    return (
      <div className="flex flex-col gap-4">
        <FileLoader />
        <Button
          label="Email ile Gönder"
          className="p-button-danger"
          onClick={onAbort}
          loading={isAborted}
        />
        <Message
          severity="warn"
          text="İptal ederseniz, dosya indirme işlemi iptal edilecek ve istekte bulunduğunuz veriler email olarak gönderilicektir."
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {!documentID ? <ColumnsAndFilters /> : <StatusMessage />}
      <ActionButtons />
    </div>
  );
};

export default ExportModalContent;
