import React from 'react';
import { Icon } from '@iconify/react';

interface FileLoaderProps {
  message?: string;
}

const FileLoader = ({
  message = 'Dosyanız oluşturuluyor...',
}: FileLoaderProps) => {
  return (
    <div className="flex flex-col items-center">
      <div className="relative w-24 h-24">
        <div className="absolute inset-0 rounded-full border-4 border-t-transparent border-red-600 animate-spin" />
        <div className="absolute inset-0 flex items-center justify-center">
          <Icon
            icon="mdi:file-excel"
            className="text-red-600"
            width="36"
            height="36"
          />
        </div>
      </div>
      <p className="text-center mt-4">{message}</p>
    </div>
  );
};

export default FileLoader;
