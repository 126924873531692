import { Message } from 'primereact/message';
import { Fieldset } from 'primereact/fieldset';
import { useSelector } from 'react-redux';
import { selectExport } from '@/store/export/exportSelectors';
import { cdnUrl } from '@/store/export/exportSlice';

const StatusMessage = () => {
  const { isDownloaded, filePath, isAborted } = useSelector(selectExport);

  const fullFilePath = `${cdnUrl}/${filePath}`;

  if (isDownloaded && filePath) {
    return (
      <Fieldset
        legend="Durum"
        className="p-4 border border-green-400 rounded-lg shadow-md mt-4"
      >
        <div className="mt-4 p-4 bg-green-100 text-green-700 rounded">
          Talep edilen dosya başarıyla oluşturuldu. Eğer otomatik indirme
          başlamadıysa{' '}
          <a href={fullFilePath} download className="text-blue-500 underline">
            buraya tıklayarak indirebilirsiniz
          </a>
          .
        </div>
      </Fieldset>
    );
  }

  if (!isDownloaded || !filePath) {
    if (isAborted) {
      return (
        <Fieldset
          legend="Durum"
          className="p-4 border border-yellow-400 rounded-lg shadow-md mt-4"
        >
          <Message
            severity="warn"
            text="Dosya indirme işlemi iptal edildi. Dosya e-posta ile gönderilecektir."
          />
        </Fieldset>
      );
    }

    return (
      <Fieldset
        legend="Durum"
        className="p-4 border border-yellow-400 rounded-lg shadow-md mt-4"
      >
        <Message
          severity="warn"
          text="Dosya hazırlanırken bir hata oluştu veya çok uzun sürdü. Dosya e-posta ile gönderilecektir."
        />
      </Fieldset>
    );
  }

  return null;
};

export default StatusMessage;
