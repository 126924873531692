let USER_PERMISSIONS = new Set<string>();

export const initUserPermissions = (permissions: string[]): void => {
  // We need to reset USER_PERMISSIONS Set object on every campus changes
  USER_PERMISSIONS.clear();

  if (!permissions?.length) return;

  USER_PERMISSIONS = new Set(permissions);

  // eslint-disable-next-line no-console
  // console.log('UserPerms:', USER_PERMISSIONS, [...USER_PERMISSIONS]);
};

export const hasPermission = (operation: string | string[]): boolean => {
  // Array of operations
  if (Array.isArray(operation)) {
    return operation.every((op) =>
      [...USER_PERMISSIONS].some(
        (opt) => opt === op || opt === `${op}_all-campuses`
      )
    );
  }

  // Single operation
  return [...USER_PERMISSIONS].some(
    (opt) => opt === operation || opt === `${operation}_all-campuses`
  );
};
