import React, { useEffect } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import {
  deselectAllColumns,
  selectAllColumns,
  updateColumn,
  updateFilter,
} from '@/store/export/exportSlice';
import { selectExport } from '@/store/export/exportSelectors';
import {
  filterDictionary,
  filterValueDictionary,
  getQueryParams,
} from '@/utils/helpers/export';
import { AppDispatch } from '@/store';

const ColumnsAndFilters = () => {
  const { columns, selectedColumns, filters = {} } = useSelector(selectExport);
  const queryParams = getQueryParams(window.location.search);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFilter(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSelectAll = () => {
    dispatch(selectAllColumns());
  };

  const handleDeselectAll = () => {
    dispatch(deselectAllColumns());
  };

  return (
    <>
      <Fieldset legend="Sütunlar" className="p-2 relative">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {columns.map((col: { name: string; key: string }) => (
            <div key={col.name} className="flex items-center">
              <Checkbox
                inputId={col.name}
                checked={!!selectedColumns[col.name]}
                onChange={() => dispatch(updateColumn(col.name))}
                className="mr-2"
              />
              <label htmlFor={col.name} className="text-sm">
                {col.key}
              </label>
            </div>
          ))}

          {columns.length === 0 && (
            <p className="italic text-red-600">
              Bu modüle ait sütunlar getirilirken bir hata oluştu.
            </p>
          )}
        </div>

        <div className="flex justify-end mt-8 gap-2 md:flex-row flex-col">
          <Button
            size="small"
            outlined
            type="button"
            onClick={handleSelectAll}
            label="Tümünü Seç"
            icon="pi pi-check"
          />
          <Button
            size="small"
            outlined
            type="button"
            onClick={handleDeselectAll}
            label="Tümünü Temizle"
            icon="pi pi-times"
          />
        </div>
      </Fieldset>

      <Fieldset
        legend="Filtreler"
        className="p-4 border border-gray-300 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-800"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {Object.entries(filters).length === 0 && (
            <p className="italic">Henüz bir filtre eklenmemiş.</p>
          )}
          {Object.entries(filters)
            .filter(([key]) => !key.includes('ID'))
            .map(([key, value]) => (
              <div
                key={key}
                className="bg-gray-50 dark:bg-gray-700 p-4 border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm"
              >
                <label className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wide">
                  {filterDictionary[key] || key.replace('_', ' ')}{' '}
                </label>
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 mt-1 break-all">
                  {filterValueDictionary[value.toString()] || value.toString()}
                </div>
              </div>
            ))}
        </div>
      </Fieldset>

      <Message
        text="Filtrelerinizi ve sütunlarınızı kontrol edin, ardından dışarı aktar butonuna tıklayın. En az bir sütun seçmelisiniz."
        className="border-primary w-full justify-content-start"
        severity="warn"
      />
    </>
  );
};

export default ColumnsAndFilters;
